









import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { BoxStep, CircularStep } from './versions'
import { StepProps } from './Step.contracts'

/**
 * Renders step molecules.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<Step>({ name: 'Step' })
export class Step extends Vue {
  /**
   * @see StepProps.currentStep
   */
  @PropSync('currentStep', { type: Number, required: false, default: () => 1 })
  public _currentStep!: StepProps['currentStep']

  /**
   * @see StepProps.label
   */
  @Prop({ type: String, required: false })
  public readonly label?: StepProps['label']

  /**
   * @see StepProps.payload
   */
  @PropSync('payload', { type: Object, required: false })
  public _payload?: StepProps['payload']

  /**
   * @see StepProps.previewComponent
   */
  @Prop({ type: Function, required: false })
  public readonly previewComponent?: StepProps['previewComponent']

  /**
   * @see StepProps.step
   */
  @Prop({ type: Number, required: true })
  public readonly step!: StepProps['step']

  /**
   * @see StepProps.step
   */
  @Prop({ type: String, required: false, default: 'box' })
  public readonly type!: string

  public get component (): VueConstructor {
    if (this.type === 'circle') {
      return CircularStep
    } else {
      return BoxStep
    }
  }

  /**
   * Determines whether current step is currently active.
   */
  public get isActive (): boolean {
    return this.step === this._currentStep
  }

  /**
   * Handles next step.
   */
  public nextStep (): void {
    const step: number = this.step + 1

    this._currentStep = step
    this.$emit('updateStep', step)
  }

  /**
   * Updates the payload by a new value
   */
  public updateKey (key: string, value: unknown): void {
    this._payload = {
      ...this._payload,
      [key]: value
    }
  }
}

export default Step
