













import { Component, Mixins } from 'vue-property-decorator'

import BaseStep from '../BaseStep'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'CircularStep' })
export class CircularStep extends Mixins<BaseStep>(BaseStep) {
  /**
   * Handles @setStep event of preview component.
   */
  public setCurrentStep (step: number): void {
    // Block availability to jump for future step.
    if (step > this._currentStep) {
      return
    }

    this._currentStep = step
  }
}

export default CircularStep
