import { PaymentMethodCode } from '../../../../contexts'

export type PaymentMethodValue = PaymentMethodCode | {
  code: PaymentMethodCode
  methodId: string
}

export interface PaymentStepConfig {
  drivers: PaymentMethodValue[]
  driversOrder?: Record<string, number>
  icons: Record<string, string>
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const PAYMENT_STEP_CONFIG_KEY = 'PaymentStep'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const PRZELEWY24 = 'przelewy24'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const defaultConfig: PaymentStepConfig = {
  drivers: [PaymentMethodCode.OnDelivery, PaymentMethodCode.PayNow, PaymentMethodCode.Transfer],
  driversOrder: undefined,
  icons: {
    cashondelivery: require('./assets/images/przy-odbiorze.svg'),
    paynow_gateway: require('./assets/images/paynow.svg'),
    banktransfer: require('./assets/images/przelew.png')
  }
}

// todo: mena:
// export const defaultConfig: AnyObject = {
//   drivers: ['cashondelivery', 'ngeniusonline', 'banktransfer'],
//   icons: {
//     cashondelivery: require('./assets/images/przy-odbiorze.svg'),
//     banktransfer: require('./assets/images/przelew.png'),
//     ngeniusonline: require('./assets/images/ngeniusonline.png')
//   }
// }

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const Przelewy24ExcludedMethods: number[] = [154, 178, 227, 238, 239, 147]
