

























































































































































import { Component, Mixins, Prop, PropSync, Vue } from 'vue-property-decorator'
import { AnyObject, Authentication, AuthServiceType } from '@movecloser/front-core'

import { UserModel } from '../../../../backoffice/auth/contracts/models'
import { PaymentMethodCode } from '../../../../contexts'
import { Inject, logger } from '../../../../support'

import { AuthMixin } from '../../../auth/shared'
import { IProfileService, ProfileServiceType } from '../../../profile/contracts'

import { CartModel } from '../../contracts'
import { CheckoutServiceType, ICheckoutService } from '../../services/checkout'

import { shippingIcon } from '../Shippings/Shippings.helpers'
import { Przelewy24PaymentMethodDefinition } from '../PaymentStep/PaymentStep.contracts'
import { paymentIcon } from '../PaymentStep/PaymentStep.helpers'
import { Przelewy24ExcludedMethods } from '../PaymentStep/PaymentStep.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<CheckoutOverview>({
  name: 'CheckoutOverview',
  components: { VClamp: () => import(/* webpackChunkName: "vue-clamp" */ 'vue-clamp') },
  created (): void {
    this.loadPrzelewy24PaymentMethod()
    this.setUserSubscriptions()
  }
})
export class CheckoutOverview extends Mixins(Vue, AuthMixin) {
  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  @Inject(CheckoutServiceType)
  protected readonly checkoutService!: ICheckoutService

  @Inject(ProfileServiceType)
  protected readonly profileService!: IProfileService

  @Prop({ type: Object, required: true })
  public readonly cart!: CartModel

  @Prop({ type: Object, required: true })
  public readonly pages!: AnyObject

  @Prop({ type: Boolean, required: true })
  public readonly isLoading!: boolean

  @Prop({ type: Number, required: true })
  public readonly lastStep!: number

  @PropSync('agreements', { type: Array, required: true })
  public _agreements!: string[]

  public availableSteps: number = 5 // including overview
  public selectedPrzelewy24Method: Przelewy24PaymentMethodDefinition | null = null
  protected expandedGroup: string[] = []
  protected readonly possibleAgreements: string[] = ['email-promotion', 'sms-promotion']
  public userSubscriptions: Record<string, boolean> | null = null

  public get checkAll (): boolean {
    return this.possibleAgreements.every((consent: string) => {
      return this._agreements.includes(consent)
    })
  }

  public set checkAll (checked: boolean) {
    this._agreements = checked ? [...this.possibleAgreements] : []
  }

  public get billingAddress () {
    return this.cart.billingAddress
  }

  public get shippingAddress () {
    return this.cart.shippingAddress
  }

  public get paymentMethod () {
    if (!this.cart.selectedPaymentMethod) {
      return null
    }

    if (this.cart.selectedPaymentMethod.code === PaymentMethodCode.Przelewy24) {
      if (this.cart.selectedPaymentMethodId > 0) {
        if (Przelewy24ExcludedMethods.includes(this.cart.selectedPaymentMethodId)) {
          return {
            title: this.$t(`front.checkout.organisms.PaymentStep.Przelewy24.${this.cart.selectedPaymentMethodId}.title`),
            image: paymentIcon[this.cart.selectedPaymentMethodId]
          }
        } else {
          return {
            title: this.selectedPrzelewy24Method?.name,
            image: this.selectedPrzelewy24Method?.imgUrl
          }
        }
      }
    }

    return {
      title: this.$t(`front.checkout.organisms.PaymentStep.method.${this.cart.selectedPaymentMethod.code}.title`),
      image: paymentIcon[this.cart.selectedPaymentMethod.code]
    }
  }

  public get shippingMethod () {
    if (!this.cart.selectedShippingMethod) {
      return null
    }

    return {
      price: this.cart.selectedShippingMethod.price.value,
      title:
        this.$t(`front.checkout.organisms.ShippingStep.method.${this.cart.selectedShippingMethod.methodCode}.title`),
      image: shippingIcon[this.cart.selectedShippingMethod.methodCode]
    }
  }

  public async setUserSubscriptions (): Promise<void> {
    if (!this.isLoggedInUser) {
      return
    }

    this.$emit('isLoading', true)
    const token = String(this.authService.token)

    try {
      const response = await this.profileService.getUserSubscriptions(token)
      this.userSubscriptions = response.agreements
    } catch (e) {
      logger(e, 'warn')
    } finally {
      this.$emit('isLoading', false)
    }
  }

  public isSubscribed (subscription: string): boolean {
    if (!this.userSubscriptions) {
      return false
    }

    return Boolean(this.userSubscriptions[subscription])
  }

  public hasAllSubscriptions (): boolean {
    return this.isSubscribed('sms') && this.isSubscribed('email')
  }

  public isExpanded (key: string): boolean {
    return this.expandedGroup.includes(key)
  }

  public expand (key: string): void {
    if (this.isExpanded(key)) {
      this.expandedGroup.splice(this.expandedGroup.indexOf(key), 1)
    } else {
      this.expandedGroup.push(key)
    }
  }

  /**
   * Fires step jump.
   */
  public jumpToStep (step: number): void {
    if (this.availableSteps > this.lastStep) {
      const diff = this.availableSteps - this.lastStep
      step = step - diff
    }
    this.$router.replace(
      { name: `${this.$route.name}`, query: { step: `${step}` } }
    )
  }

  /**
   * Handles placing order.
   */
  public async onOrder (): Promise<void> {
    this.$emit('onOrder')
  }

  protected async loadPrzelewy24PaymentMethod (): Promise<void> {
    if (this.cart.selectedPaymentMethod!.code !== PaymentMethodCode.Przelewy24) {
      return
    }

    try {
      const methods = await this.checkoutService.loadPaymentMethods(this.cart.total.value)
      const method = methods.find(m => m.id === this.cart.selectedPaymentMethodId)
      this.selectedPrzelewy24Method = method ?? null
    } catch (e) {
      logger(e, 'error')
    }
  }
}

export default CheckoutOverview
