import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { StepProps } from './Step.contracts'
import { VueConstructor } from 'vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component
export class BaseStep extends Vue {
  /**
   * @see StepProps.currentStep
   */
  @PropSync('currentStep', { type: Number, required: false, default: () => 1 })
  public _currentStep!: StepProps['currentStep']

  /**
   * @see StepProps.label
   */
  @Prop({ type: String, required: false })
  public readonly label?: StepProps['label']

  /**
   * @see StepProps.payload
   */
  @PropSync('payload', { type: Object, required: false })
  public _payload?: StepProps['payload']

  /**
   * @see StepProps.previewComponent
   */
  @Prop({ type: Function, required: false })
  public readonly previewComponent?: StepProps['previewComponent']

  /**
   * @see StepProps.step
   */
  @Prop({ type: Number, required: true })
  public readonly step!: StepProps['step']

  /**
   * Determines preview component to render.
   */
  public get component (): VueConstructor {
    if (!this.hasPreviewComponent) {
      throw new Error('[Step]: Preview component is not defined')
    }

    return this.previewComponent as VueConstructor
  }

  /**
   * Determines whether label for step is defined.
   */
  public get hasLabel (): boolean {
    return typeof this.label !== 'undefined' && this.label.length > 0
  }

  /**
   * Determines whether preview component is present and is VueConstructor.
   */
  public get hasPreviewComponent (): boolean {
    return typeof this.previewComponent !== 'undefined' &&
      typeof this.previewComponent === 'function'
  }

  /**
   * Determines whether current step is currently active.
   */
  public get isActive (): boolean {
    return this.step === this._currentStep
  }

  /**
   * Determines whether step is currently in preview mode.
   */
  public get isPreview (): boolean {
    return this.step < this._currentStep
  }

  /**
   * Handles next step.
   */
  public nextStep (): void {
    const step: number = this.step + 1

    this._currentStep = step
    this.$emit('updateStep', step)
  }

  /**
   * Handles @setStep event of preview component.
   */
  public setCurrentStep (step: number): void {
    this._currentStep = step
  }

  /**
   * Updates the payload by a new value
   */
  public updateKey (key: string, value: unknown): void {
    this._payload = {
      ...this._payload,
      [key]: value
    }
  }
}
export default BaseStep
