



















import { Component, Prop, Vue } from 'vue-property-decorator'

import { StepPreviewProps } from './Step.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'StepPreview' })
export class StepPreview extends Vue {
  /**
   * @see StepPreviewProps.payload
   */
  @Prop({ type: Object, required: false })
  public readonly payload?: StepPreviewProps['payload']

  /**
   * @see StepPreviewProps.previewComponent
   */
  @Prop({ type: Function, required: false })
  public readonly previewComponent?: StepPreviewProps['previewComponent']

  /**
   * @see StepPreviewProps.payload
   */
  @Prop({ type: Number, required: true })
  public readonly step!: StepPreviewProps['step']

  /**
   * @see StepPreviewProps.label
   */
  @Prop({ type: String, required: false })
  public readonly label!: StepPreviewProps['label']

  /**
   * Emits @setStep event with current step.
   */
  public setStep (): void {
    this.$emit('setStep', this.step)
  }
}

export default StepPreview
