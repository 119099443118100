









































import { Component, Mixins, Watch, Inject as VueInject } from 'vue-property-decorator'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY, logger } from '../../../support'
import { CartTax, OrderData, OrderResponse } from '../../../contexts'

import { BenefitProgram } from '../../loyalty/contracts/programs'
import { CartAnalyticsMixin } from '../../shared/mixins/cart-analytics.mixin'
import { FullscreenLoader } from '../../shared/molecules/Loader/FullscreenLoader.vue'
import { SidesLayout } from '../../shared/molecules/SidesLayout'

import CartSummary from '../organisms/CartSummary/CartSummary.vue'
import { CartCoupons } from '../organisms/CartCoupons'
import { CheckoutOverview } from '../organisms/CheckoutOverview'
import { CircularStepper } from '../molecules/CircularStepper'
import { ORDER_KEY } from '../services/cart'
import { SingleCartItem } from '../organisms/SingleCartItem'
import { Step } from '../molecules/Step'
import { translateToCartItemEnhanced } from '../molecules/CartItem/CartItem.helpers'

import { CheckoutBase } from './base'
import { CheckoutAgreement } from '../contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<CheckoutTwo>({
  name: 'CheckoutTwo',
  components: {
    CartCoupons,
    CartSummary,
    FullscreenLoader,
    SidesLayout,
    SingleCartItem,
    Step,
    CircularStepper,
    CheckoutOverview
  },
  mounted () {
    this.pages = this.siteService?.getActiveSiteUrls()
    this.logo = this.siteService.getActiveSite().logo.light
    if (this.programs.includes(BenefitProgram.FriendsAndFamily)) {
      this.logo = this.siteService.getActiveSite().logo.faf
    }
    this.verifyIfStepSet()
    if (!this.cartLoading) {
      this.updateCheckoutFromCart()
    }
  }
})
export class CheckoutTwo extends Mixins(CheckoutBase, CartAnalyticsMixin) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  public logo: string = ''
  public shippingMethod: number | null = null
  public programs: Array<string> = []

  public translateToCartItem = translateToCartItemEnhanced

  public lastStep: number = 5

  public getLoyaltyPrograms (programs: Array<string>) {
    this.programs = programs
  }

  /**
   * FIXME
   */
  public onStep (payload: any): void {
    this.lastStep = payload.lastStep
    if (payload.step === 3) {
      this.shippingMethod = this.cart?.selectedShippingMethod?.price.value || 0
    }
  }

  public async placeOrder (): Promise<void> {
    if (!this.cart?.isSet()) {
      return
    }
    try {
      this.eventBus.emit('app:checkout.pre-purchase', this.getPurchasePayload(this.cart))
    } catch (e) {
      logger(e, 'warn')
    }
    this.placingOrder = true
    document.body.classList.add('loading-order') // fixme: ukrywa tajemniczy niewylapany wyjatek

    try {
      const response = await this.checkoutService.placeOrder(this.cart.id)
      let tax: number | undefined
      if (this.cart && this.cart.hasOwnProperty('taxes')) {
        tax = this.cart.taxes.reduce((acc: number, tax: CartTax) => {
          return acc + tax.amount.value
        }, 0)
      }

      this.eventBus.emit('app:checkout.purchase', {
        ...this.getPurchasePayload(this.cart ?? null, tax),
        transactionId: response ? response.orderNumber : ''
      })

      await this.afterOrderPlaced(response)

      setTimeout(() => {
        document.body.classList.remove('loading-order')
      }, 1000)

      if (
        this.checkoutPayload && (
          this.agreements.includes(CheckoutAgreement.EmailPromotion) ||
          this.agreements.includes(CheckoutAgreement.SmsPromotion)
        )) {
        this.eventBus.emit('app:newsletter.subscribe', {
          email: this.checkoutPayload.user.email,
          name: this.checkoutPayload.user.firstName,
          allow_marketing: this.agreements.includes(CheckoutAgreement.EmailPromotion) ? 'true' : 'false',
          allow_sms_marketing: this.agreements.includes(CheckoutAgreement.SmsPromotion) ? 'true' : 'false'
        })
      }
    } catch (e) {
      logger(e, 'warn')
      this.globalError = (e as Error).message
    }

    this.placingOrder = false
  }

  public onIsLoading (value: boolean): void {
    this.isLoading = value
  }

  protected async saveOrderData (order: OrderResponse): Promise<void> {
    if (!this.cart || !this.cart.selectedShippingMethod) {
      throw new Error('Missing or invaild cart')
    }
    // ,
    //   cart: this.cart,
    //     deliveryTime: this.deliveryTime,
    //     applied_coupon_code: this.currentCouponCode ? this.currentCouponCode.code : null
    const orderData: OrderData = {
      ...order,
      cart: this.cart,
      deliveryFrom: '1',
      deliveryTo: '3',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      total: this.$options.filters!.currency(this.cart.getTotalPrice())
    }

    localStorage.setItem(ORDER_KEY, JSON.stringify(orderData))
  }

  @Watch('cart')
  protected initCheckout () {
    this.updateCheckoutFromCart()
  }

  @Watch('programs')
  protected onIsLoggedInUser () {
    if (this.programs.includes(BenefitProgram.FriendsAndFamily)) {
      this.logo = this.siteService.getActiveSite().logo.faf
    }
  }

  protected verifyIfStepSet (): void {
    if (typeof this.$route.query.step !== 'string' || this.$route.query.step.length === 0) {
      this.moveToStep(1, true)
      return
    }

    const stepCandidate: number = parseInt(this.$route.query.step)
    if (stepCandidate < 1 || stepCandidate > this.lastStep) {
      this.moveToStep(1, true)
    }
  }
}

export default CheckoutTwo
