





















import { Component, Mixins } from 'vue-property-decorator'

import { StepPreview } from '../StepPreview.vue'
import BaseStep from '../BaseStep'

/**
 * Renders box step molecule.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<BoxStep>({
  name: 'BoxStep',
  components: { StepPreview }
})
export class BoxStep extends Mixins<BaseStep>(BaseStep) {}
export default BoxStep
